import React from 'react';
import ReactGA from 'react-ga4';

import ApplicationTemplate from './pages/home/ApplicationTemplate';

ReactGA.initialize('GA-352419639');

export const App = () => {
  return <ApplicationTemplate />;
};
