import React, { useCallback, useMemo, useRef, useState } from 'react';
import { faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Article } from '../../articles/Articles';
import { Modal } from '../../modal/Modal';

import cn from './ShareModal.module.scss';

export const ShareModal = ({ open, setOpen, data }: { open: boolean; setOpen: (open: boolean) => void; data: Article[] }) => {
  const [tooltip, setTooltip] = useState('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const today = new Date().toLocaleDateString();

  const share_text = useMemo(
    () =>
      `Today's Top Five - ${today}\nhttps://morepositiv.ai\n\n${data
        .slice(0, 5)
        .map((article, index) => `${index + 1}. ${article.Title}\n${article.Url}\n`)
        .join('\n')}`,
    [data, today],
  );

  const handleTooltip = (message: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setTooltip(message);
    timeoutRef.current = setTimeout(() => {
      setTooltip('');
    }, 3000);
  };

  const onClickEmail = useCallback(() => {
    const subject = encodeURIComponent("Today's Top Five - morePositiv");
    const body = encodeURIComponent(share_text);

    window.open(`mailto:?subject=${subject}&body=${body}`);

    handleTooltip('Opening default email app...');
  }, [share_text]);

  const onClickCopy = useCallback(() => {
    navigator.clipboard
      .writeText(share_text)
      .then(() => {
        handleTooltip("Today's Top Five copied to clipboard!");
      })
      .catch((error) => {
        console.error('Failed to copy text to clipboard:', error);
      });
  }, [share_text]);

  // const onClickFacebook = () => {
  //   const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(share_text)}`;
  //   window.open(facebookShareUrl, '_blank', 'noopener,noreferrer');
  //   handleTooltip('Opening Facebook...');
  // };

  const onClickLinkedin = () => {
    const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(share_text)}`;
    window.open(linkedinShareUrl, '_blank', 'noopener,noreferrer');
    handleTooltip('Opening LinkedIn...');
  };

  const onClickTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(share_text)}`;
    window.open(twitterShareUrl, '_blank', 'noopener,noreferrer');
    handleTooltip('Opening X...');
  };

  const Header = (
    <div>
      <h2>Today's Top Five</h2>
      <h3>{today}</h3>
    </div>
  );

  const Footer = <div className={cn.tooltip}>{tooltip}</div>;

  return (
    <Modal open={open} onClose={() => setOpen(false)} width={700} header={Header} footer={Footer}>
      <div className={cn.top_five_container}>
        {data.slice(0, 5).map((article, index) => (
          <div className={cn.top_five} key={index}>
            <div className={cn.title}>
              {index + 1}. {article.Title}
            </div>
            <a className={cn.link} href={article.Url} target="_blank" rel="noreferrer">
              {article.Url}
            </a>
          </div>
        ))}
      </div>
      <div className={cn.line} />
      <h3 className={cn.share_header}>Share Today's Top Five!</h3>
      <div className={cn.share_container}>
        <FontAwesomeIcon className={cn.share} icon={faEnvelope} onClick={onClickEmail} />
        <FontAwesomeIcon className={cn.share} icon={faCopy} onClick={onClickCopy} />
        <FontAwesomeIcon className={cn.share} icon={faLinkedin} onClick={onClickLinkedin} />
        {/* <FontAwesomeIcon className={cn.share} icon={faInstagram} onClick={onClickInstagram} /> */}
        {/* <FontAwesomeIcon className={cn.share} icon={faFacebook} onClick={onClickFacebook} /> */}
        <FontAwesomeIcon className={cn.share} icon={faXTwitter} onClick={onClickTwitter} />
      </div>
    </Modal>
  );
};
