import React, { useEffect, useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faArrowTrendDown, faArrowTrendUp, faEquals, faMinus, faPlus, faShare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '../modal/Modal';

import { ShareModal } from './modals/ShareModal';

import cn from './PositivPulse.module.scss';

export interface Ratio {
  Positive: number;
  Negative: number;
  Neutral: number;
  Positive_increase: boolean;
  Negative_increase: boolean;
  Neutral_increase: boolean;
}

export const PositivPulse: React.FC = () => {
  const [data, setData] = useState<Ratio | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [info_open, setInfoOpen] = useState(false);
  const [share_open, setShareOpen] = useState(false);

  useEffect(() => {
    const fetchCSV = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_AWS}/ratio_data.json`).then((data) => data.json());
        const [today, yesterday] = res.sort((a: any, b: any) => {
          const dateA = new Date(a.Day);
          const dateB = new Date(b.Day);
          return dateB.getTime() - dateA.getTime();
        });
        const today_positive = Math.round((today.Positive / today.Total) * 1000) / 10;
        const today_negative = Math.round((today.Negative / today.Total) * 1000) / 10;
        const today_neutral = Math.round((today.Neutral / today.Total) * 1000) / 10;
        const yesterday_positive = Math.round((yesterday.Positive / yesterday.Total) * 1000) / 10;
        const yesterday_negative = Math.round((yesterday.Negative / yesterday.Total) * 1000) / 10;
        const yesterday_neutral = Math.round((yesterday.Neutral / today.Total) * 1000) / 10;

        const normalized = {
          Positive: today_positive,
          Negative: today_negative,
          Neutral: today_neutral,
          Positive_increase: today_positive > yesterday_positive,
          Negative_increase: today_negative > yesterday_negative,
          Neutral_increase: today_neutral > yesterday_neutral,
        };
        setData(normalized);
        setIsVisible(true);
      } catch (error) {
        console.error('Error fetching CSV data:', error);
      }
    };

    fetchCSV();
  }, []);

  return (
    <>
      <div className={cn.container} style={{ maxHeight: isVisible ? '1000px' : '0', transition: 'max-height 2s ease-in-out', overflow: 'hidden' }}>
        {!!data && (
          <div className={cn.pulse}>
            <div className={cn.title_container}>
              <p className={cn.title}>Positiv News Pulse</p>
              <FontAwesomeIcon className={cn.info} icon={faInfoCircle} onClick={() => setInfoOpen(true)} />
            </div>
            <div className={cn.ratio_container}>
              <div className={cn.ratio}>
                <div className={cn.icon}>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
                <p>{data.Positive}%</p>
                <div className={cn.trend}>
                  <FontAwesomeIcon
                    className={data.Positive_increase ? cn.increase : cn.decrease}
                    icon={data.Positive_increase ? faArrowTrendUp : faArrowTrendDown}
                  />
                </div>
              </div>
              <div className={cn.ratio}>
                <div className={cn.icon}>
                  <FontAwesomeIcon icon={faEquals} />
                </div>
                <p>{data.Neutral}%</p>
                {/* <div className={cn.trend}>
                <FontAwesomeIcon
                className={data.Neutral_increase ? cn.increase : cn.decrease}
                icon={data.Neutral_increase ? faArrowTrendUp : faArrowTrendDown}
                />
                </div> */}
              </div>
              <div className={cn.ratio}>
                <div className={cn.icon}>
                  <FontAwesomeIcon icon={faMinus} />
                </div>
                <p>{data.Negative}%</p>
                <div className={cn.trend}>
                  <FontAwesomeIcon
                    className={data.Negative_increase ? cn.increase : cn.decrease}
                    icon={data.Negative_increase ? faArrowTrendUp : faArrowTrendDown}
                  />
                </div>
              </div>
              <div className={cn.share} onClick={() => setShareOpen(true)}>
                <FontAwesomeIcon icon={faShare} />
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal open={info_open} onClose={() => setInfoOpen(false)} icon={faInfoCircle}>
        <p>
          The Positiv News Pulse shows the daily ratio of positive and negative news identified by our AI, with all other content classified as
          neutral. This tracker showcases our AI's ability to understand content and measure the balance of sentiment in online news, highlighting the
          need for more uplifting stories in today's digital landscape.
        </p>
      </Modal>
      {!!data && <ShareModal open={share_open} setOpen={setShareOpen} data={data} />}
    </>
  );
};
