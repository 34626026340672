import React from 'react';

import cn from './Banner.module.scss';

export const Banner = () => {
  return (
    <div className={cn.banner}>
      <div className={cn.container}>
        <h1 className={cn.title}>AI Bringing You Brighter News</h1>
        <p className={cn.description}>
          At More Positiv, we curate uplifting news, highlighting breakthroughs, discoveries, and inspiring stories. Powered by advanced AI, we
          analyze content sentiment to deliver the top positive stories each day. With the Positiv News Pulse, stay updated on the overall sentiment
          of online news daily. Join us in celebrating the brighter side of news!
        </p>
      </div>
    </div>
  );
};
