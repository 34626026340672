import React, { useCallback, useMemo, useRef, useState } from 'react';
import { faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCopy, faEnvelope, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faArrowTrendDown, faArrowTrendUp, faEquals, faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '../../modal/Modal';
import { Ratio } from '../PositivPulse';

import cn from './ShareModal.module.scss';

export const ShareModal = ({ open, setOpen, data }: { open: boolean; setOpen: (open: boolean) => void; data: Ratio }) => {
  const [tooltip, setTooltip] = useState('');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const today = new Date().toLocaleDateString();

  const share_text = useMemo(
    () =>
      `Today's Positiv News Pulse - ${today}\nhttps://morepositiv.ai\n\nPositive: ${data.Positive}% ${data.Positive_increase ? '⬆' : '⬇'}\nNeutral: ${
        data.Neutral
      }%\nNegative: ${data.Negative}% ${data.Negative_increase ? '⬆' : '⬇'}`,
    [data, today],
  );

  const handleTooltip = (message: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setTooltip(message);
    timeoutRef.current = setTimeout(() => {
      setTooltip('');
    }, 3000);
  };

  const onClickEmail = useCallback(() => {
    const subject = encodeURIComponent("Today's Positiv News Pulse - morePositiv");
    const body = encodeURIComponent(share_text);

    window.open(`mailto:?subject=${subject}&body=${body}`);

    handleTooltip('Opening default email app...');
  }, [share_text]);

  const onClickCopy = useCallback(() => {
    navigator.clipboard
      .writeText(share_text)
      .then(() => {
        handleTooltip("Today's Positiv News Pulse copied to clipboard!");
      })
      .catch((error) => {
        console.error('Failed to copy text to clipboard:', error);
      });
  }, [share_text]);

  // const onClickFacebook = () => {
  //   const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?quote=${encodeURIComponent(share_text)}`;
  //   window.open(facebookShareUrl, '_blank', 'noopener,noreferrer');
  //   handleTooltip('Opening Facebook...');
  // };

  const onClickLinkedin = () => {
    const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?text=${encodeURIComponent(share_text)}`;
    window.open(linkedinShareUrl, '_blank', 'noopener,noreferrer');
    handleTooltip('Opening LinkedIn...');
  };

  const onClickTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(share_text)}`;
    window.open(twitterShareUrl, '_blank', 'noopener,noreferrer');
    handleTooltip('Opening X...');
  };

  const Header = (
    <div>
      <h2>Today's Positiv News Pulse</h2>
      <h3>{today}</h3>
    </div>
  );

  const Footer = <div className={cn.tooltip}>{tooltip}</div>;

  return (
    <Modal open={open} onClose={() => setOpen(false)} width={600} header={Header} footer={Footer}>
      <div className={cn.ratio_container}>
        <div className={cn.ratio}>
          <div className={cn.icon}>
            <FontAwesomeIcon icon={faPlus} />
          </div>
          <p>{data.Positive}%</p>
          <div className={cn.trend}>
            <FontAwesomeIcon
              className={data.Positive_increase ? cn.increase : cn.decrease}
              icon={data.Positive_increase ? faArrowTrendUp : faArrowTrendDown}
            />
          </div>
        </div>
        <div className={cn.ratio}>
          <div className={cn.icon}>
            <FontAwesomeIcon icon={faEquals} />
          </div>
          <p>{data.Neutral}%</p>
          {/* <div className={cn.trend}>
                <FontAwesomeIcon
                className={data.Neutral_increase ? cn.increase : cn.decrease}
                icon={data.Neutral_increase ? faArrowTrendUp : faArrowTrendDown}
                />
                </div> */}
        </div>
        <div className={cn.ratio}>
          <div className={cn.icon}>
            <FontAwesomeIcon icon={faMinus} />
          </div>
          <p>{data.Negative}%</p>
          <div className={cn.trend}>
            <FontAwesomeIcon
              className={data.Negative_increase ? cn.increase : cn.decrease}
              icon={data.Negative_increase ? faArrowTrendUp : faArrowTrendDown}
            />
          </div>
        </div>
      </div>
      <p className={cn.info}>
        <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: 16, marginRight: 8 }} />
        The Positiv News Pulse shows the daily ratio of positive and negative news identified by our AI, with all other content classified as neutral.
        This tracker showcases our AI's ability to understand content and measure the balance of sentiment in online news, highlighting the need for
        more uplifting stories in today's digital landscape.
      </p>
      <div className={cn.line} />
      <h3 className={cn.share_header}>Share Today's Positiv News Pulse!</h3>
      <div className={cn.share_container}>
        <FontAwesomeIcon className={cn.share} icon={faEnvelope} onClick={onClickEmail} />
        <FontAwesomeIcon className={cn.share} icon={faCopy} onClick={onClickCopy} />
        <FontAwesomeIcon className={cn.share} icon={faLinkedin} onClick={onClickLinkedin} />
        {/* <FontAwesomeIcon className={cn.share} icon={faInstagram} onClick={onClickInstagram} /> */}
        {/* <FontAwesomeIcon className={cn.share} icon={faFacebook} onClick={onClickFacebook} /> */}
        <FontAwesomeIcon className={cn.share} icon={faXTwitter} onClick={onClickTwitter} />
      </div>
    </Modal>
  );
};
