import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';

import { Articles } from './components/articles/Articles';
import { Banner } from './components/banner/Banner';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { PositivPulse } from './components/positiv-pulse/PositivPulse';

import cn from './ApplicationTemplate.module.scss';

export default () => {
  useEffect(() => {
    ReactGA.send({ hitType: 'page_view', page: window.location.pathname, title: 'Happy News Page' });
  }, []);

  return (
    <>
      <div className={cn.page}>
        <Header />
        <PositivPulse />
        <Banner />
        <Articles />
        <Footer />
      </div>
    </>
  );
};
