import React, { useState } from 'react';

import cn from './SubscribeCard.module.scss';

export const SubscribeCard = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubscribe = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email || !emailRegex.test(email)) {
      setMessage('Please enter a valid email.');
      return;
    }

    setIsSubmitting(true);

    try {
      const mcFormUrl = 'https://thepositiv.us18.list-manage.com/subscribe/post?u=e1b541d9780f0108dc81f49c7&id=e09e886cc2&f_id=00c068e1f0';

      const formData = new FormData();
      formData.append('EMAIL', email);
      formData.append('b_e1b541d9780f0108dc81f49c7_e09e886cc2', '');

      await fetch(mcFormUrl, {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      });

      setMessage('Subscribed successfully!');
      setEmail('');
    } catch (error) {
      setMessage('Subscription failed. Please try again.');
    }

    setIsSubmitting(false);
  };

  return (
    <div className={cn.card}>
      <div className={cn.title}>Subscribe for more Good News!</div>
      <input type="email" placeholder="Enter your email..." value={email} onChange={(e) => setEmail(e.target.value)} required />
      <button onClick={handleSubscribe} disabled={isSubmitting} style={{ letterSpacing: '1.2px' }}>
        {isSubmitting ? 'SIGNING UP...' : 'SIGN UP'}
      </button>
      {message && <p className={cn.message}>{message}</p>}
    </div>
  );
};
